<template>
    <el-table :data="tableData" style="width: 100%"  v-loading="loading">
        <el-table-column prop="serial_number" label="设备号" width="220" />
        <el-table-column prop="merchant_no" label="商户号"/>
        <el-table-column prop="store.name" label="门店名称" width="220"  />
        <el-table-column prop="store.address" label="门店地址" width="220"  />
        <el-table-column prop="updated_at" label="创建时间" width="120">
            <template #default="scope">
                <span>{{dayjs(scope.row.created_at).format("YYYY-MM-DD")}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="mark" label="备注" />
        <el-table-column label="操作" width="120">
            <template #default="scope">
                <el-button type="text" @click="handleEditshow(scope.row)">{{ scope.row.mark ? '修改备注':'添加备注' }}</el-button>
            </template>
        </el-table-column>
        <el-table-column>
            <template #default="empty">
                <empty-block :empty="empty" />
            </template>
        </el-table-column>
    </el-table>
    <div class="pagination">
        <el-pagination
            :page-size="pagination.count"
            layout="prev, pager, next"
            :total="pagination.total"
            @current-change="handleSizeChange"
        />
    </div>


    <el-dialog v-model="dialogVisible" title="设置备注" width="30%">
        <el-input v-model="eidtdata.mark" :rows="2" type="textarea" placeholder="请输入备注信息"/>
        <template #footer>
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="handleEdit">保存</el-button>
        </template>
    </el-dialog>
</template>

<style lang="less" scoped>

.pagination{
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 10px 0;
}

</style>

<script setup>
import { ref,onMounted ,getCurrentInstance} from "vue";
import dayjs from "dayjs"
import { ElMessage } from 'element-plus'

const { proxy } = getCurrentInstance()

onMounted(() => {getTableData()})
const loading=ref(true)
const tableData=ref([])
const pagination=ref({total:0,count:0})

const getTableData = async (data) => {
    loading.value=true
    await proxy.$api.getDeviceData(data).then(res=>{
       if (res.status===200&&res.success) {
        tableData.value=res.data
        pagination.value=res.pagination
       }
       loading.value=false
    })
 }

const handleSizeChange=(val)=>{serchDate.value.page=val,getTableData(serchDate.value)}
const serchDate=ref({page:1})


const eidtdata=ref({id:"",mark:"",_method:"PUT"})
const dialogVisible=ref(false)
const handleEditshow=(row)=>{
    eidtdata.value.id=row.id
    dialogVisible.value=true
}

const handleEdit= async()=>{
    await proxy.$api.editDeviceData(eidtdata.value.id,eidtdata.value).then(res=>{
        console.log(res);
        if (res.status===200&&res.success) {
            dialogVisible.value=false
            getTableData()
            ElMessage({message: '修改成功',type: 'success'})
        }else{
            ElMessage.error('修改失败')
        }
    })
}

</script>